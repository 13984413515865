import { Alpine } from '../../../vendor/livewire/livewire/dist/livewire.esm';

export default {
  watchedAuctionIds: null,
  setWatchedAuctionIds(watchedAuctionIds) {
    this.watchedAuctionIds = watchedAuctionIds
  },
  isWatchingItem(auctionId) {
    if (Alpine.store('user').hasUser()) {
      return this.watchedAuctionIds.includes(auctionId)
    }
    return false
  },
  addWatchedItem(auctionId) {
    if (Alpine.store('user').hasUser()) {
      this.watchedAuctionIds.push(auctionId)
    }
  },
  removeWatchedItem(auctionId) {
    if (Alpine.store('user').hasUser()) {
      this.watchedAuctionIds = this.watchedAuctionIds.filter(id => id !== auctionId)
    }
  },
  async onWatchItem(auctionId) {
    const loginUrl = '/login?intent=add-to-watch-list';

    if (!Alpine.store('user').hasUser()) {
      return window.location.href = loginUrl
    }

    this.isWatchingItem(auctionId)
      ? this.removeWatchedItem(auctionId)
      : this.addWatchedItem(auctionId)

    try {
      await window.axios.post(route('auctions.watch', auctionId))
    } catch (err) {
      // if the user has logged out in another tab, redirect them to the login page
      if (err?.response?.status === 401) {
        return window.location.href = loginUrl
      }
    }
  }
}
